





































import Select from "@/components/pc/CurrencySelect.vue";
import balanceBg from "@/assets/images/blockChainBrowser/address/balanceBg-mobile.png";
import Vue from "vue";
import { filterNum } from "@/utils/utils";
export default Vue.extend({
  components: {
    Select,
  },
  props: {
    forzen: [Number, Object],
    balance: [Number, Object],
    selectedValue: String,
    optionsList: Array,
    handleSelectChange: Function,
  },
  methods: {
    filterNum,
  },
  data() {
    return {
      balanceBg,
    };
  },
});

















































import { filterNum } from "@/utils/utils";
import Vue from "vue";

export default Vue.extend({
  props: {
    receive: [Number, Object],
    send: Number,
    value: Number,
    txCount: Number,
  },
  mounted() {
    this.setFontSize();
  },
  watch: {
    receive() {
      this.setFontSize();
    },
    send() {
      this.setFontSize();
    },
    value() {
      this.setFontSize();
    },
    txCount() {
      this.setFontSize();
    },
  },
  methods: {
    filterNum,
    setFontSize() {
      const father = this.$refs.a1 as HTMLElement;
      const a = this.$refs.a2 as HTMLElement;
      const b = this.$refs.b2 as HTMLElement;
      const c = this.$refs.c2 as HTMLElement;
      const d = this.$refs.d2 as HTMLElement;
      let beSmall = false;
      [a, b, c, d].some((el: HTMLElement) => {
        if (el.offsetWidth >= father.offsetWidth) {
          beSmall = true;
        }
      });
      beSmall &&
        [a, b, c, d].forEach((el: HTMLElement) => {
          el.style.fontSize = "10px";
        });
    },
  },
});

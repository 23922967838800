




















import mAddressDisplay from './m-address-display.vue'
import VueTypedMixins from 'vue-typed-mixins'
import AddressOverview from '@/mixin/componentsMixin/AddressOverview'
import MAddressBlueCard from './m-address-blue-card.vue'
import MFourParams from './m-four-params.vue'
import MAddressBalanceGraph from './m-address-balance-graph.vue'
export default VueTypedMixins(AddressOverview).extend({
  props: {
    balance: [Number, Object],
    selectedValue: String,
    optionsList: Array,
    handleSelectChange: Function,
    frozen: [Number, Object],
  },
  components: {
    mAddressDisplay,
    MAddressBlueCard,
    MFourParams,
    MAddressBalanceGraph,
  },
})

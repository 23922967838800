








































import Vue from 'vue'
import noData from '@/assets/images/blockChainBrowser/address/mobile-chart-no-data.png'
import chartsInAddr from '@/components/chartsInAddr.vue'
export default Vue.extend({
  components: { chartsInAddr },
  data() {
    return {
      noData,
      scaleList: ['W1', 'M1', 'M6', 'Y1'],
      selectedScale: 'W1',
    }
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"consensus-node",staticStyle:{"background":"#fafbfc"},attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading"}},[_c('div',{staticClass:"line flex justify-between items-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("lang.txDetail.txHash"))+": "),_c('router-link',{staticClass:"tx-hash",attrs:{"to":{
          path: '/tradeHash',
          query: {
            hash: _vm.row.hash,
          },
        }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.row.hash,10))+" ")])],1),_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.row.block_time)))])]),_c('div',{staticClass:"box",staticStyle:{"background":"rgba(248, 164, 87, 0.06)"}},[_c('div',{staticClass:"flex items-center justify-between",staticStyle:{"margin-top":"4px"}},[_c('div',{staticClass:"text-darkBlue text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.packingNode"))+" ")])]),_c('div',{staticClass:"flex justify-between items-center",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.address.nodeAdr"))+": ")]),_c('span',{staticClass:"text-text-color text-xs text-orange"},[_c('router-link',{attrs:{"to":{
              path: '/address',
              query: {
                address: _vm.row.maker_addr[0],
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.row.maker_addr[0],6))+" ")])],1)]),_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.blockReward"))+": ")]),_c('span',{staticClass:"font-bold text-darkBlue text-xs"},[_vm._v(" "+_vm._s(_vm.row.amount / 1e8)+"YCC ")])])])]),_vm._l((Object.keys(_vm.row.voter_detail)),function(item,index){return _c('div',{key:index,staticClass:"vote-node"},[_c('div',{staticClass:"flex items-center justify-between",staticStyle:{"margin-top":"4px"}},[_c('div',{staticClass:"text-darkBlue text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.votingNode"))+" ")])]),_c('div',{staticClass:"flex justify-between items-center",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.address.nodeAdr"))+": ")]),_c('span',{staticClass:"text-text-color text-xs"},[_c('router-link',{staticClass:"adr",attrs:{"to":{
              path: '/address',
              query: {
                address: item,
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item,6))+" ")])],1)]),_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.blockReward"))+": ")]),_c('span',{staticClass:"font-bold text-darkBlue text-xs"},[_vm._v(" "+_vm._s(Number(_vm.row.voter_detail[item]) * 0.25)+" YCC ")])])])])}),_c('div',{staticClass:"foundation"},[_c('div',{staticClass:"flex items-center justify-between",staticStyle:{"margin-top":"4px"}},[_c('div',{staticClass:"text-darkBlue text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.foundationNode"))+" ")])]),_c('div',{staticClass:"flex justify-between items-center",staticStyle:{"margin-top":"5px"}},[_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.address.nodeAdr"))+": ")]),_c('span',{staticClass:"text-text-color text-xs"},[_c('router-link',{staticClass:"adr",attrs:{"to":{
              path: '/address',
              query: {
                address: _vm.foundation,
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.foundation,6))+" ")])],1)]),_c('div',[_c('span',{staticClass:"text-text-color text-xs"},[_vm._v(" "+_vm._s(_vm.$t("lang.block.blockReward"))+": ")]),_c('span',{staticClass:"font-bold text-darkBlue text-xs"},[_vm._v(" 6 YCC ")])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }

























import MCopyBtn from '@/components/mobile/m-copyBtn.vue'
import qrcodeIcon from '@/assets/images/blockChainBrowser/address/qrcodeIcon.png'
import { Popover } from 'element-ui'
import Vue from 'vue'
export default Vue.extend({
  components: { MCopyBtn, Popover },
  data() {
    return {
      qrcodeIcon,
    }
  },
})

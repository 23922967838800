var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-balance-graph"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('dir',[_vm._v(" "+_vm._s(_vm.$t('lang.address.accountBalance'))+" ")]),_c('div',{},_vm._l((_vm.scaleList),function(s,i){return _c('span',{key:i,staticClass:"text-text-color ml-3",class:{
          ' text-footer-color': s === _vm.selectedScale,
        },on:{"click":function($event){_vm.selectedScale = s}}},[_vm._v(" "+_vm._s(s)+" ")])}),0)],1),_c('div',{staticClass:"relative"},[_c('charts-in-addr',{staticStyle:{"height":"110px"},attrs:{"scale":_vm.selectedScale,"grid":{
        top: '10px',
        bottom: '25px',
        left: '50px',
        right: '20px',
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
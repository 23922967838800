















































































import MChainSearch from "@/components/mobile/m-chainSearch.vue";
import MTabs from "@/components/mobile/m-tabs.vue";
import MPageContainer from "@/components/mobile/m-pageContainer.vue";
import MVotePack from "@/components/mobile/m-vote-pack.vue";
import address from "@/mixin/address";
import VueTypedMixins from "vue-typed-mixins";
import MAddressOverview from "./components/m-address-overview/index.vue";
import MTxItem from "@/components/mobile/m-txItem.vue";
import Select from "@/components/pc/Select.vue";

export default VueTypedMixins(address).extend({
  components: {
    MChainSearch,
    MAddressOverview,
    MTabs,
    MPageContainer,
    MVotePack,
    Select,
    MTxItem,
  },
});
